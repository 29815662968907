<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span v-if="!isCustom" class="lead collapse-title">{{ title }}</span>
        <div v-else class="lead collapse-title">
          <feather-icon
            :id="`collapse-description-${collapseItemID}`"
            :icon="customIcon"
            size="20"
            :class="[customIconVariant ? `text-${customIconVariant}` : '', 'mr-1']"
          />
          <b-tooltip
            v-if="customTooltipTitle"
            :title="customTooltipTitle"
            :target="`collapse-description-${collapseItemID}`"
          />
          <b-badge v-if="customSubTitle" :variant="customBadgeVariant">{{ customSubTitle }}</b-badge>
          <span> {{ title }} </span>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body :class="{'p-0': paddingNone}">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCollapse, BBadge } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    // PROPS CUSTOMIZADAS
    paddingNone: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: String,
      default: ""
    },
    customSubTitle: {
      type: String,
      default: "",
    },
    customBadgeVariant: {
      type: String,
      default: "light-info",
    },
    customIconVariant: {
      type: String,
      default: "",
    },
    customTooltipTitle: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      visible: false,
      collapseItemID: "",
      openOnHover: this.$parent.hover,
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit("visible", val);
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
  },
};
</script>
