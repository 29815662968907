<template>
  <b-overlay
    :variant="skin"
    :show="isloading"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <b-row align-v="start" v-if="record">
      <b-col cols="auto">
        <b-img
          thumbnail
          fluid
          alt="Thumbnail"
          class="thumbnail"
          :style="{ padding: urlImage ? '0px' : '20px' }"
          :src="
            urlImage ? urlImage : require('@/assets/images/pages/sem-foto.png')
          "
        />
      </b-col>
      <b-col>
        <b-row align-h="between">
          <b-col>
            <div>
              <h4 class="mb-0">
                {{ record.name }}
              </h4>
              <span class="card-text">ID: {{ record.id }}</span>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="outline-info"
              @click="onClickEdit()"
              v-if="!record.system_default"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              class="ml-1"
              variant="outline-warning"
              @click="controlPublicProject()"
              :id="`project-url-public-${record.id}`"
            >
              <feather-icon icon="Share2Icon" />
              <b-tooltip
                triggers="hover"
                :target="`project-url-public-${record.id}`"
                title="URL Pública"
                :delay="{ show: 100, hide: 50 }"
              />
            </b-button>

            <button--c
              :variant="'danger'"
              :iconsize="'14'"
              :icon="'Trash2Icon'"
              :permission="'permission.project.delete'"
              :title="null"
              @clicked="confirmDelete()"
              class="ml-1"
              v-if="!record.system_default"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <table style="width: 100%; max-width: 1000px" class="mt-1">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold">Primeira Aula</span>
                </th>
                <td class="pb-50">
                  {{ record.first_class_date | dateFormat }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="ClockIcon" class="mr-75" />
                  <span class="font-weight-bold">Duração</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ record.lesson_duration + "h" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="InboxIcon" class="mr-75" />
                  <span class="font-weight-bold">Calendar ID</span>
                </th>
                <td style="max-width: 300px" class="pb-50">
                  {{ record.google_calendar_id }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="HomeIcon" class="mr-75" />
                  <span class="font-weight-bold">Empresa</span>
                </th>
                <td class="pb-50">
                  {{ record.company.name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">Disp. na Plataforma</span>
                </th>
                <td class="pb-50">
                  {{ record.avaliable_platform ? "Sim" : "Não" }}
                </td>
              </tr>
              <tr v-if="record.link_first_lesson">
                <th class="pb-50">
                  <feather-icon icon="HomeIcon" class="mr-75" />
                  <span class="font-weight-bold">Url primeira aula</span>
                </th>
                <td class="pb-50">
                  <a @click="onClickYoutubelink(record)">{{
                    record.youtube_first_link
                  }}</a>
                </td>
              </tr>
              <tr v-if="record.system_default">
                <th class="pb-50">
                  <feather-icon icon="SettingsIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Esse projeto é padrão do sistema</span
                  >
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="ListIcon" class="mr-75" />
                  <span class="font-weight-bold">Classificações</span>
                </th>
                <td class="pb-50" v-if="!lstClassification || lstClassification.length == 0">
                  Sem classificação adicionada
                </td>
              </tr>
              <tr v-for="(itemClassification, index) in lstClassification" :key="index">
                <th></th>
                <td colspan="2">
                  <table>
                    <tr>
                      <td class="pb-50">
                        <strong>{{ itemClassification.name }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-50">
                        <b-badge class="mr-50" v-for="item in itemClassification.items" variant="light-info"
                          :key="item.id">
                          {{ item.name }}
                        </b-badge>
                      </td>
                    </tr>
                    <hr />
                  </table>
                </td>
              </tr>
              
              <tr
                v-if="
                  record.projectProducts && record.projectProducts.length > 0
                "
              >
                <th class="pb-50">
                  <feather-icon icon="LayersIcon" class="mr-75" />
                  <span class="font-weight-bold">Produtos por demanda:</span>
                </th>
              </tr>
              <tr v-for="(jobTypeProducts, index) in jobTypeProductList" :key="index">
                <th></th>
                <td colspan="2">
                  <table>
                    <tr>
                      <td class="pb-50">
                        <b-badge class="mr-50" variant="light-warning">
                          {{ jobTypeProducts.jobType }}
                        </b-badge>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-50">
                        <b-badge
                          class="mr-50"
                          v-for="product in jobTypeProducts.products"
                          variant="light-info"
                          :key="product.id"
                        >
                          {{ product.name }}
                        </b-badge>
                      </td>
                    </tr>
                    <hr/>
                  </table>
                </td>
              </tr>
              <tr v-if="!record.projectProducts || record.projectProducts.length == 0">
                <th class="pb-50">
                  <feather-icon icon="LayersIcon" class="mr-75" />
                  <span class="font-weight-bold">Nenhum produto associado</span>
                </th>
                <td class="pb-50">
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="record">
      <b-col md="6" class="mt-1">
        <b-card
          title="Descrição do YouTube"
          v-if="record.youtube_description"
          class="mb-0 custom-card"
        >
          <b-form-textarea
            rows="10"
            v-model="record.youtube_description"
            placeholder="Informe uma breve descrição (opcional)"
            plaintext
          />
        </b-card>
      </b-col>
      <b-col md="6" class="mt-1">
        <b-card
          title="Descrição CTA"
          v-if="record.youtube_description_second"
          class="mb-0 custom-card"
        >
          <b-form-textarea
            rows="10"
            v-model="record.youtube_description_second"
            placeholder="Informe uma breve descrição (opcional)"
            plaintext
          />
        </b-card>
      </b-col>
      <b-col md="6" class="mt-1">
        <b-card
          title="Descrição de Rodapé do Google Calendar"
          v-if="record.calendar_description_footer"
          class="mb-0 custom-card"
        >
          <quill-editor
            disabled
            v-model="record.calendar_description_footer"
            :options="editorOption"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BImg } from "bootstrap-vue";
import _projectsService from "@/services/projects-service";
import _productsService from "@/services/audiovisual/products-service";
import { quillEditor } from "vue-quill-editor";
import useAppConfig from "@core/app-config/useAppConfig";
import jobTypeService from '@/services/audiovisual/job-type-service';

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BImg,
    quillEditor,
  },
  data() {
    return {
      record: null,
      urlImage: "",
      isloading: false,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      editorOption: {
        theme: "bubble",
      },
      products: [],
      jobTypeList: [],
      jobTypeProductList: [],
      lstClassification: [],
    };
  },
  async created() {
    await this.getProducts();
    await this.getJobTypes();
    this.getRecord();
  },
  filters: {
    dateFormat: (date) => {
      if (!date) return "--";
      let separate_date = date.split("T")[0];
      let dates = separate_date.split("-");
      return `${dates[2]}/${dates[1]}/${dates[0]}`;
    },
  },
  methods: {
    getRecord() {
      if (this.$route.params.id > 0) {
        this.isloading = true;
        _projectsService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.isloading = false;
            if (this.record.projectProducts?.length > 0) {
              for (const p of this.record.projectProducts) {
                let jobType = this.jobTypeProductList.find((f) => f.id == p.job_type_id);

                if (!jobType) {
                  let currentJobType = this.jobTypeList.find((f) => f.value == p.job_type_id);

                  jobType = {
                    id: p.job_type_id,
                    jobType: currentJobType.label,
                    products: [],
                  }

                  this.jobTypeProductList.push(jobType);
                }

                const currentProduct = this.products.find((f) => f.id == p.product_id);

                jobType.products.push({
                  id: p.product_id,
                  name: currentProduct.name,
                });
              }
            }

            if (this.record.youtube_image) {
              this.urlImage = `${res.pathimage}/${this.record.youtube_image}`;
            }

            if (this.record?.classifications_extra?.length > 0 || this.record?.classifications_inherited?.length > 0) {
              this.getClassifications();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    async getProducts() {
      try {
        let { content } = await _productsService.showProductsAll();
        this.products = content.map((m) => {
          return {
            name: `${m.name}`,
            id: m.id,
            selected: false,
          };
        });
      } catch (error) {
        this.$utils.toastError("Notificação", error);
      }
    },
    async getJobTypes() {
      try {
        let { content } = await jobTypeService.getSimplifiedJobTypes();
        this.jobTypeList = content.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
      } catch (error) {
        this.$utils.toastError("Notificação", error);
      }
    },
    onClickEdit() {
      this.$router.push({
        path: `/pedagogic/project/${this.record.id}`,
      });
    },
    onClickYoutubelink(_record) {
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = `${_record.youtube_first_link}`;
      a.click();
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    controlPublicProject() {
      if (!this.record.acl_rule_id || this.record.acl_rule_id == "") {
        this.makePublicProject();
      } else {
        if (this.record.google_calendar_id) {
          this.openPublicUrl();
        }
      }
    },
    makePublicProject() {
      this.isloading = true;
      _projectsService
        .makePublicProject(this.record.id)
        .then(() => {
          this.record.acl_rule_id = "default";
          this.openPublicUrl();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    openPublicUrl() {
      let url = `${process.env.VUE_APP_CALENDAR_PUBLIC_URL.replace(
        "{0}",
        this.record.google_calendar_id
      )}`;

      const el = document.createElement("textarea");
      el.value = url;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    excluir() {
      this.isloading = true;
      _projectsService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    async getClassifications() {
      const _promisses = [_projectsService.getCategoriesForGoals(this.record.company_id), _projectsService.getNamesClassifications(this.$route.params.id)];
      Promise.all(_promisses)
        .then((_pms) => {
          this.populateClassifications(_pms[0], _pms[1]);
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    populateClassifications(_dataCategories, _dataNames) {
      if (!_dataCategories || !_dataNames) return;
      if (!_dataCategories.content || !_dataNames.content) return;
      
      var classificationsTypes = _dataCategories.content.data;
      var classifications = _dataNames.content.data;
      this.lstClassification = [];
      
      if (!classificationsTypes || !classifications || classificationsTypes.length == 0 || classifications.length == 0) return;
      classificationsTypes.forEach(category => {
        var categories = {
          name: category.name,
          items: []
        }

        classifications.forEach(classification => {
          if (classification?.parent_id === category.id) {
            categories.items.push(classification)
          }
        });

        this.lstClassification.push(categories);

      });

      let rootless = []
        classifications.forEach(classification => {
          if (!classificationsTypes.some(s => s.id === classification.parent_id)) {
            rootless.push(classification)
          }
        })

        if (rootless.length > 0) {
          this.lstClassification.push({
            name: 'Outras',
            items: rootless
          })
        }
        
    },
  },
};
</script>

<style scoped>
.custom-card {
  border: 1px solid #dae1e7;
  border-radius: 5px;
}
.thumbnail {
  height: 164px;
  width: 291px;
}
table th {
  max-width: 110px;
}
</style>
