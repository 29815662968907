<template>
  <b-overlay
    :variant="skin"
    :show="isloading"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <validation-observer ref="eventRules">
      <b-form class="px-1" @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nome do evento *">
              <validation-provider
                #default="{ errors }"
                name="Nome do evento"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  placeholder="Nome do Evento"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Descrição Evento">
              <div class="form-label-group">
                <quill-editor v-model="record.description" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Imagem Evento">
              <div class="form-label-group">
                <img
                  @click="$refs.fileInput.click()"
                  :src="urlImage"
                  id="thumbnail-youtube"
                  class="img-fluid rounded-shadow cursor-pointer"
                />
                <input
                  style="display: none"
                  ref="fileInput"
                  type="file"
                  accept="image/*"
                  @change="onFileChange"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Recorrencia">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.recurrence"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" v-if="record.recurrence">
            <b-form-group label="Periodicidade">
              <v-select
                v-model="frequencySelected"
                :options="frequencyOptions"
                autocomplete="off"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-if="record.recurrence">
            <b-form-group label="Recorrencia Quantidade">
              <b-form-input
                v-model="record.recurrence_quantity"
                type="number"
                :state="validationFrequency"
              />
              <b-form-invalid-feedback :state="validationFrequency">
                Diário no máximo 10
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <button--c
              :variant="'info'"
              :iconsize="'20'"
              :icon="null"
              :permission="
                record.id > 0
                  ? 'permission.events.edit'
                  : 'permission.events.create'
              "
              :title="'Salvar'"
              @clicked="validationForm()"
              class="mr-1"
            />
            <button--c
              v-if="record.id > 0"
              :variant="'gradient-danger'"
              :iconsize="'20'"
              :icon="null"
              :permission="'permission.events.delete'"
              :title="'Excluir'"
              @clicked="confirmDelete()"
              class="mr-1"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>
<script>
import _eventService from "@/services/event-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import useAppConfig from "@core/app-config/useAppConfig";
import { required } from "@validations";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dto: {
      type: Object,
      required: true,
    },
  },
  computed: {
    validationFrequency() {
      //2 - Diário
      return this.frequencySelected?.value === "2" &&
        this.record.recurrence_quantity > 10
        ? false
        : null;
    },
  },
  data() {
    return {
      debounceMilliseconds: 300,
      timeout: null,
      isloading: false,
      record: {},
      projects: [],
      selectedFile: null,
      urlImage: `${require("@/assets/images/pages/sem-foto.png")}`,
      frequencySelected: null,
      frequencyOptions: [
        { value: "0", label: "Mensal" },
        { value: "1", label: "Semanal" },
        { value: "2", label: "Diário" },
      ],
    };
  },
  created() {
    this.initials();
    this.getRecord();
    localize("pt_BR", pt_br);
  },
  methods: {
    validationForm() {
      this.$refs.eventRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    initials() {
      this.record = this.dto;
      this.record.description = "";
      this.record.name = "";
    },
    getRecord() {
      if (this.dto.id > 0) {
        this.isloading = true;
        _eventService
          .find(this.dto.id)
          .then((res) => {
            if (res.content.recurrence) {
              this.frequencySelected = this.frequencyOptions.filter(
                (f) => f.value === res.content.frequency.toString()
              )[0];
            }

            this.record = res.content;
            if (this.record.image) {
              this.urlImage = `${res.pathimage}/${this.record.image}`;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    save() {
      let payload = new FormData();
      if (this.frequencySelected) {
        if (this.validationFrequency == false) {
          this.$utils.toastError("Notificação", 'Recorrência diaria máximo de 10');
          return
        }
        this.record.frequency = this.frequencySelected.value;
      }

      if (this.selectedFile) {
        payload.append("thumbnail", this.selectedFile);
      }
      payload.append("dados", JSON.stringify(this.record));

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _eventService.create(payload)
          : _eventService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Evento salvo com sucesso.");
          this.$emit("result", { status: "ok" });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.isloading = true;
      _eventService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$emit("result", "ok");
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onFileChange(e) {
      e.preventDefault();
      this.selectedFile = e.target.files[0];
      this.urlImage = URL.createObjectURL(e.target.files[0]);
    },
  },
};
</script>
<style>
#thumbnail-youtube {
  border: 1px solid #999;
  width: 100px;
  height: 70px;
  border-radius: 10%;
}

#thumbnail-youtube:hover {
  opacity: 0.5;
}
</style>