<template>
  <b-card>
    <!-- MODAL SELECIONAR O PROFESSOR DO CLONE-->
    <b-modal
        id="modal-select-teacher"
        ref="modal-select-teacher"
        cancel-variant="outline-secondary"
        ok-title="Prosseguir com o clone"
        cancel-title="Cancelar"
        centered
        title="Selecione os professores do curso"
        ok-variant="info btn-icon"
        @ok="checkFormTeacherValidity"
        no-close-on-backdrop        
      >
        <b-form ref="formTeacher">
          <b-form-group label="Professor(es):">          
            <v-select
              v-model="teachersSelected"
              :options="teachers"
              :clearable="true"
              multiple
              autocomplete="off"
              @search="
                (res) => {
                  searchTeacher(res);
                }
              "
              :loading="isLoadingTeacher"
            >
              <template v-slot:option="option">
                <h6 class="text-capitalize m-0">{{ option.label }}</h6>
                <small v-if="option.email">
                  {{ option.email }}
                </small>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>     
          </b-form-group> 
        </b-form>
    </b-modal>
    <!-- FIM MODAL SELECIONAR O PROFESSOR DO CLONE-->
    <b-overlay :variant="skin" :show="!project" spinner-variant="primary" spinner-type="grow">
      <h4 class="mb-2 text-truncate">{{ project }}</h4>
    </b-overlay>
    <b-row class="mb-1 mb-sm-0">
      <b-col md="8">
        <button--c
          :variant="'info'"
          :iconsize="'20'"
          :icon="null"
          :permission="'permission.course.create'"
          :title="'Cadastrar Curso'"
          @clicked="onClickSave(null)"
          class="mb-2 mr-2"
          v-if="!project_system_default"
        />
        <button--c
          :variant="notificar ? 'info' : 'outline-info'"
          :iconsize="'20'"
          :icon="null"
          :permission="'permission.notification.teacher.send'"
          :title="'Notificar Professor'"
          @clicked="notifyTeacher"
          class="mb-2"
          v-if="!project_system_default"
        />
      </b-col>
      <b-col md="4">
        <b-input-group>
          <b-form-input
            placeholder="Pesquise por Disciplina, Professor..."
            autocomplete="off"
            v-model="search"
            @keyup.enter="filter"
          />
          <b-input-group-append>
            <b-button variant="info" @click="filter">
              <feather-icon icon="SearchIcon" class="mr-50" />
              <span>Pesquisar</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
      <Table
        :fields="fields"
        :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
        @orderBy="getRecordsOrderBy"
        border="full"
        responsive
      >
        <template #teachers="data">
          <div v-if="data.item.teachers.length > 0">
            <div
              v-for="(teacher, index) in data.item.teachers"
              :key="index"
              class="text-center"
              :class="[
                data.item.teachers.length > 1 &&
                index != data.item.teachers.length - 1
                  ? 'mb-25'
                  : 'm-0',
              ]"
            >
              <b-badge
                class="d-block"
                :variant="teacher.teacher_res ? 'light-info' : 'light-dark'"
                v-b-tooltip.hover.right="teacher.email"
              >
                {{ teacher.name }}
              </b-badge>
            </div>
          </div>
          <div v-else>--</div>
        </template>

        <template #name="data">
          <div
            :data-text="data.item.name"
            class="text-truncate ellipsis"
            style="max-width: 400px"
          >
            {{ data.item.name }}
          </div>
        </template>

        <template #discipline="data">
          <div
            :data-text="data.item.discipline.name"
            class="text-truncate ellipsis"
            style="max-width: 400px"
          >
            {{ data.item.discipline.name }}
          </div>
        </template>

        <template #classification="data">
          <div
            :data-text="data.item.classification.name"
            class="text-truncate ellipsis"
            style="max-width: 400px"
          >
            {{ data.item.classification.name }}
          </div>
        </template>

        <template #rowselect="data">
          <div class="ch-init-icon">
            <b-form-checkbox
              v-model="data.item.notifySelected"
              plain
              class="vs-checkbox-con"
              @change="notifyCheck"
              :disabled="isDisable(data.item.notification_date)"
            >
            </b-form-checkbox>
          </div>
        </template>
        <template #notify="data">
          <div class="text-nowrap notify-status">
            <b-avatar
              :variant="data.item.notification_date ? 'success' : 'danger'"
              :id="`scheduled-description-${data.item.id}`"
              size="25"
            >
              <feather-icon
                size="16"
                :icon="data.item.notification_date ? 'CheckIcon' : 'XIcon'"
              />
            </b-avatar>
          </div>
        </template>
        <template #actions="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`details-event-row-${data.item.id}`"
              icon="TrelloIcon"
              size="16"
              class="mx-1"
              v-b-tooltip.hover.top="'Detalhes do Curso'"
              @click="onClickDetails(data.item)"
            />
            <feather-icon
              :id="`edit-event-row-${data.item.id}`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              v-b-tooltip.hover.top="'Editar Curso'"
              @click="onClickSave(data.item)"
              v-if="!project_system_default"
            />

            <feather-icon
                :id="`btn-clone-${data.item.id}`"
                icon="CopyIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="
                  data.item.is_clone
                    ? `Clone (Original: ${data.item.parent_id})`
                    : 'Clonar'
                "
                @click="cloneConfirm(data.item)"
                v-if="!project_system_default"
              />
          </div>
        </template>
      </Table>

      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        :per-page="size"
        v-if="rows > 1"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-overlay>
    <b-sidebar
      ref="coursesDetailsSideBar"
      id="coursesDetailsSideBar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isSave"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isSave">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 v-if="dto.id" class="mb-0">Editar Curso #{{ dto.id }}</h5>
          <h5 v-else class="mb-0">Cadastrar Curso</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <project-course-form @result="onResult" :dto="dto" />
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import { VBToggle, VBTooltip } from "bootstrap-vue";
import Table from "@/components/Table.vue";
import _coursesService from "@/services/courses-service";
import _notificationService from "@/services/notification-template-service";
import project_couse from "./project-course";
import _projectsService from "@/services/projects-service";
import useAppConfig from "@core/app-config/useAppConfig";
import _teachersService from "@/services/teachers-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
    "project-course-form": project_couse,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      project: null,
      project_system_default: false,
      project_company_id: null,
      notificar: false,
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "rowselect", label: "" },
        { key: "name", label: "Curso", orderBy: { type: "back" } },
        {
          key: "discipline",
          label: "Disciplina",
          orderBy: { type: "back", customKey: "discipline.name" },
        },
        {
          key: "classification",
          label: "Classificação",
          orderBy: { type: "back", customKey: "classification.name" },
        },
        { key: "teachers", label: "Professor" },
        { key: "notify", label: "Notificado" },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: "",
      },
      isSave: false,
      dto: null,
      courseCloned: {},
      isLoadingTeacher: false,
      teachers: [],
      teachersSelected: [],
    };
  },
  created() {
    this.getRecords(this.currentePage);
    this.getProject();
  },
  methods: {
    getProject() {
      if (this.$route.params.id > 0) {
        _projectsService
          .find(this.$route.params.id)
          .then((res) => {
            if (res.content) {
              this.project = res.content.name;
              this.project_company_id = res.content.company_id;
              this.project_system_default = res.content.system_default;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _coursesService
        .show(_page, this.$route.params.id, this.orderByParams)
        .then((res) => {
          if (res.content) {
            const list = res.content.map(c => {
                  return ({
                  ...c,
                  classification: {
                    id: c.classification_platform_id ?? null,
                    name: c.classification_platform_name ?? '--'
                  }
                })
            })
            this.list.push({ page: _page, itens: list });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSave(_record) {
      this.dto = {
        project_id: _record ? _record.project_id : this.$route.params.id,
        id: _record ? _record.id : 0,
        company_id: this.project_company_id
      };
      this.isSave = true;
    },
    onClickDetails(record) {
      this.$router.push({
        path: `/pedagogic/project-view/${this.$route.params.id}/course/${record.id}`,
      });
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
      this.filter();
    },
    notifyTeacher() {
      if (this.notificar) {
        let count = 0;
        this.isloading = true;
        let payload = new FormData();
        let course = [];

        if (
          this.list[this.currentePage] &&
          this.list[this.currentePage].itens.length > 0
        ) {
          this.list[this.currentePage].itens.forEach((item) => {
            if (item != null) {
              if (item.notifySelected) {
                course.push({ id: item.id });
                count++;
              }
            }
          });
        }

        payload.append("dados", JSON.stringify(course));
        //promisse
        const _createOrUpdate = _notificationService.notifyTeacher(payload);

        _createOrUpdate
          .then((res) => {
            if (res.content == count) {
              let text =
                count > 1
                  ? "Professores notificados com sucesso"
                  : "Professor notificado com sucesso";
              this.$utils.toast("Notificação", text);
            } else {
              this.$utils.toastError(
                "Atenção",
                "Algumas notificações falharam."
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(
            () => (
              (this.isloading = false),
              (this.list = []),
              this.getRecords(this.currentePage)
            )
          );
      } else return;
    },
    notifyCheck() {
      this.notificar = false;
      if (
        this.list[this.currentePage] &&
        this.list[this.currentePage].itens.length > 0
      ) {
        this.list[this.currentePage].itens.forEach((item) => {
          if (item != null) {
            if (item.notifySelected) {
              this.notificar = true;
              return;
            }
          }
        });
      }
    },
    isDisable(notification_date) {
      return notification_date ? true : false;
    },
    cloneConfirm(item) {
      let textTitle = `Deseja clonar este curso (${item.id})?`;

      this.$swal({
        title: textTitle,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Sim, quero clonar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.teachers = [];
          this.teachersSelected = [];
          this.selectTeacher(item.id)
        }
      });
    },
    selectTeacher(record_id){      
      this.courseCloned = {
        data: {
          id: record_id,
        },
      };
      this.$refs["modal-select-teacher"].show();
    },
    checkFormTeacherValidity(_modal) {
        if(this.teachersSelected && this.teachersSelected.length >= 1)
        {          
          this.courseCloned.data.Teachers = this.teachersSelected.map(m => {return {teacher_id: m.value}});
          this.cloneCourse();
        }
        else
        {
          _modal.preventDefault()
          return this.$utils.toastError("Notificação","Selecione ao menos 1 professor");
        }
    },
    cloneCourse() {
     let payload = this.courseCloned;
      this.isloading = true;
      _coursesService
        .clone(payload)
        .then((res) => {
          if (res.content && res.content.id > 0) {
            this.$utils.toast("Notificação", "Clone realizado  com sucesso.");
            this.$router.push({
              path: `/pedagogic/project-view/${this.$route.params.id}/course/${res.content.id}`,
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.isLoadingTeacher) {
          this.isLoadingTeacher = true;
          setTimeout(() => {
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  this.teachers = this.populardropdown(
                    res.content,
                    "name",
                    "id",
                    "email"
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isLoadingTeacher = false));
          }, 1500);
        }
      }
    },    
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
  },
};
</script>
<style>
.notify-status {
  margin-left: 30px;
}
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  padding: 0.5rem 0rem;
  font-size: 12px;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  font-size: 12px;
  z-index: 10;
}
</style>