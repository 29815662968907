var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.isloading,"spinner-variant":"primary","spinner-type":"grow"}},[_c('b-modal',{ref:"modal-discipline",attrs:{"hide-footer":"","title":"Criar Disciplina"}},[_c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.isloadingModal,"spinner-variant":"primary","spinner-type":"grow"}},[_c('validation-observer',{ref:"disciplineNewRules"},[(_vm.disciplineNew)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nome","autocomplete":"off"},model:{value:(_vm.disciplineNew.name),callback:function ($$v) {_vm.$set(_vm.disciplineNew, "name", $$v)},expression:"disciplineNew.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2193485967)})],1),_c('div',{staticClass:"d-block text-center"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info"},on:{"click":function($event){return _vm.validationFormModal()}}},[_vm._v(" Salvar ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"gradient-danger"},on:{"click":function($event){return _vm.closeModal('modal-discipline')}}},[_vm._v(" Cancelar ")])],1)],1):_vm._e()],1)],1)],1),_c('validation-observer',{ref:"courseRules"},[(_vm.record)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isloadingAutocomplete)?_c('b-spinner',{staticClass:"float-right",attrs:{"small":""}}):_vm._e(),_c('b-form-group',{attrs:{"label":"Nome *"}},[_c('validation-provider',{attrs:{"name":"Nome do curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nome"},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1950059051)})],1),(_vm.record.canChange)?_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"10","sm":"11"}},[_c('b-form-group',{attrs:{"label":"Disciplina *"}},[_c('validation-provider',{attrs:{"name":"Disciplina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.disciplines,"input-props":_vm.inputDisciplineProps,"section-configs":_vm.disciplineConfigs,"loading":_vm.isloadingAutocomplete,"disabled":""},on:{"input":_vm.getDisciplines},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_vm._v(" "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.queryDiscipline),callback:function ($$v) {_vm.queryDiscipline=$$v},expression:"queryDiscipline"}}),_c('small',{staticClass:"text-danger position-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,892188840)})],1)],1),_c('b-col',{attrs:{"cols":"2","sm":"1"}},[_c('b-button',{staticClass:"btn-icon mt-1",staticStyle:{"float":"right"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.openModal()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1):_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Disciplina *"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.disciplineSelected.name),callback:function ($$v) {_vm.$set(_vm.disciplineSelected, "name", $$v)},expression:"disciplineSelected.name"}})],1)],1)],1),(_vm.record.canChange)?_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Classificação"}},[_c('label',[_vm._v("(Base de Dados da Plataforma)")]),_c('validation-provider',{attrs:{"name":"Classificação","rules":'needSaving:' + _vm.classificationSelected.id + ',' + _vm.classificationSelected.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.classifications,"input-props":_vm.inputClassificationProps,"section-configs":_vm.classificationConfigs,"loading":_vm.isloadingAutocomplete,"disabled":""},on:{"input":_vm.getClassifications},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_vm._v(" "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.queryClassification),callback:function ($$v) {_vm.queryClassification=$$v},expression:"queryClassification"}}),_c('small',{staticClass:"text-danger position-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3402118804)})],1)],1)],1):_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Classificação"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.classificationSelected.name),callback:function ($$v) {_vm.$set(_vm.classificationSelected, "name", $$v)},expression:"classificationSelected.name"}})],1)],1)],1),(_vm.record.canChange)?_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição do curso"}},[_c('b-form-input',{attrs:{"placeholder":"Descrição"},model:{value:(_vm.record.description),callback:function ($$v) {_vm.$set(_vm.record, "description", $$v)},expression:"record.description"}})],1)],1)],1):_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.record.description),callback:function ($$v) {_vm.$set(_vm.record, "description", $$v)},expression:"record.description"}})],1)],1)],1),(_vm.record.canChange)?_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Professor(es) *"}},[_c('validation-provider',{attrs:{"name":"professor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.teachers,"clearable":true,"multiple":"","autocomplete":"off","loading":_vm.isLoadingTeacher},on:{"search":function (res) {
                    _vm.searchTeacher(res);
                  }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('h6',{staticClass:"text-capitalize m-0"},[_vm._v(_vm._s(option.label))]),(option.email)?_c('small',[_vm._v(" "+_vm._s(option.email)+" ")]):_vm._e()]}},{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.teachersSelected),callback:function ($$v) {_vm.teachersSelected=$$v},expression:"teachersSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3506770664)})],1)],1)],1):_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"12"}},[(_vm.teachersSelected.length > 1)?_c('span',[_vm._v("Professores")]):_c('span',[_vm._v("Professor")])]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},_vm._l((_vm.teachersSelected),function(tt,index){return _c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(tt.email),expression:"tt.email",modifiers:{"hover":true,"top":true}}],key:index,staticClass:"mr-1 mb-1 cursor-pointer"},[_vm._v(" "+_vm._s(tt.label)+" ")])}),1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Duração *"}},[_c('validation-provider',{attrs:{"name":"Duração","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Duração","config":{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true,
              }},model:{value:(_vm.record.duration),callback:function ($$v) {_vm.$set(_vm.record, "duration", $$v)},expression:"record.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2786285222)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"fluid",attrs:{"label":"Número de Aulas *"}},[_c('validation-provider',{attrs:{"name":"Número de Aulas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"id":"sb-inline","min":"1","inline":""},model:{value:(_vm.record.lesson_number),callback:function ($$v) {_vm.$set(_vm.record, "lesson_number", $$v)},expression:"record.lesson_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1446836860)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Imagem"}},[_c('div',{staticClass:"form-label-group"},[_c('img',{staticClass:"img-fluid rounded-shadow cursor-pointer",attrs:{"src":_vm.urlImage,"id":"thumbnail-youtube"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}}),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFileChange}})])]),_c('button--c',{staticClass:"mr-1",attrs:{"variant":'info',"iconsize":'20',"icon":null,"permission":_vm.record.id > 0
          ? 'permission.course.edit'
          : 'permission.course.create',"title":'Salvar'},on:{"clicked":function($event){return _vm.validationForm()}}}),(_vm.record.id > 0)?_c('button--c',{staticClass:"mr-1",attrs:{"variant":'gradient-danger',"iconsize":'20',"icon":null,"permission":'permission.course.delete',"title":'Excluir'},on:{"clicked":function($event){return _vm.confirmDelete()}}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }