import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if (options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/audio-visual/products${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/products/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/products', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/products', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/products/${id}`)
  },
  showProductsAll: () => {
    return _http.get(`/api/audio-visual/products/products-all`)
  },
  mediaTypes: () => {
    return _http.get(`/api/audio-visual/products/media-types`)
  },
  showProductsAllByCompany: (company) => {
    return _http.get(`/api/audio-visual/products/products-all-by-company/${company}`)
  },
  showProductsAllByProject: (project, appointmentId) => {
    const parans = appointmentId && appointmentId > 0 ? `?appointmentId=${appointmentId}` : '';
    return _http.get(`/api/audio-visual/products/products-all-by-project/${project}${parans}`)
  },
}