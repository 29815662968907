import HttpService from './HttpService'

const _http = new HttpService()
export const _classificationService = {
  autoComplete: (search, company_id) => {
    return _http.get(`/api/classification/auto-complete?page=${0}&search=${search}&asc&data.company_id=${company_id}`)
  },
  platformAutoComplete: (company_id, search) => {
    return _http.get(`/api/classification/platform?data.company_id=${company_id}&data.name=${search}`)
  },
  platformGetOne: (company_id, classification_platform_id) => {
    return _http.get(`/api/classification/platform/${company_id}/${classification_platform_id}`)
  },
  checkClassificationAliasAvailability: (company_id, alias) => {
    return _http.get(`/api/classification/platform/${company_id}/alias-availability?name=${alias}&t=1`)
  }
}