<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <!-- MODAL DISCIPLINA -->
    <b-modal ref="modal-discipline" hide-footer title="Criar Disciplina">
      <b-overlay :variant="skin" :show="isloadingModal" spinner-variant="primary" spinner-type="grow">
        <validation-observer ref="disciplineNewRules">
          <b-form class="p-2" @submit.prevent v-if="disciplineNew">
            <b-form-group label="Nome">
              <validation-provider #default="{ errors }" name="Nome" rules="required">
                <b-form-input v-model="disciplineNew.name" placeholder="Nome" autocomplete="off" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-block text-center">
              <b-button variant="info" class="mr-1" @click="validationFormModal()">
                Salvar
              </b-button>
              <b-button variant="gradient-danger" class="mr-1" @click="closeModal('modal-discipline')">
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
    <!-- MODAL DISCIPLINA -->
    <!-- Form -->
    <validation-observer ref="courseRules">
      <b-form class="p-2" @submit.prevent v-if="record">
        <b-spinner class="float-right" v-if="isloadingAutocomplete" small />
        <b-form-group label="Nome *">
          <validation-provider #default="{ errors }" name="Nome do curso" rules="required">
            <b-form-input v-model="record.name" placeholder="Nome" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row class="d-flex align-items-center" v-if="record.canChange">
          <b-col cols="10" sm="11">
            <b-form-group label="Disciplina *">
              <validation-provider #default="{ errors }" name="Disciplina" rules="required">
                <vue-autosuggest v-model="queryDiscipline" :suggestions="disciplines" :input-props="inputDisciplineProps"
                  :section-configs="disciplineConfigs" @input="getDisciplines" :loading="isloadingAutocomplete" disabled>
                  <template slot-scope="{ suggestion }">
                    {{ suggestion.item.name }}
                  </template>
                </vue-autosuggest>
                <small class="text-danger position-absolute">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2" sm="1">
            <b-button variant="info" class="btn-icon mt-1" @click="openModal()" style="float: right">
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" v-else>
          <b-col cols="12" sm="12">
            <b-form-group label="Disciplina *">
              <b-form-input v-model="disciplineSelected.name" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- CLASSIFICAÇÃO PLATAFORMA -->
        <b-row class="d-flex align-items-center" v-if="record.canChange">
          <b-col cols="12" sm="12">
            <b-form-group label="Classificação">
              <label>(Base de Dados da Plataforma)</label>
              <validation-provider #default="{ errors }" name="Classificação"
                :rules="'needSaving:' + classificationSelected.id + ',' + classificationSelected.name">
                <vue-autosuggest v-model="queryClassification" :suggestions="classifications"
                  :input-props="inputClassificationProps" :section-configs="classificationConfigs"
                  @input="getClassifications" :loading="isloadingAutocomplete" disabled>
                  <template slot-scope="{ suggestion }">
                    {{ suggestion.item.name }}
                  </template>
                </vue-autosuggest>
                <small class="text-danger position-absolute">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" v-else>
          <b-col cols="12" sm="12">
            <b-form-group label="Classificação">
              <b-form-input v-model="classificationSelected.name" disabled />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- DESCRIÇÃO -->
        <b-row class="d-flex align-items-center" v-if="record.canChange">
          <b-col cols="12" sm="12">
            <b-form-group label="Descrição do curso">
              <b-form-input v-model="record.description" placeholder="Descrição" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" v-else>
          <b-col cols="12" sm="12">
            <b-form-group label="Descrição">
              <b-form-input v-model="record.description" disabled />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- TEACHER -->
        <b-row class="d-flex align-items-center" v-if="record.canChange">
          <b-col cols="12" sm="12">
            <b-form-group label="Professor(es) *">
              <validation-provider #default="{ errors }" name="professor" rules="required">
                <v-select v-model="teachersSelected" :options="teachers" :clearable="true" multiple autocomplete="off"
                  @search="(res) => {
                      searchTeacher(res);
                    }
                    " :loading="isLoadingTeacher">
                  <template v-slot:option="option">
                    <h6 class="text-capitalize m-0">{{ option.label }}</h6>
                    <small v-if="option.email">
                      {{ option.email }}
                    </small>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" v-else>
          <b-col cols="12">
            <span v-if="teachersSelected.length > 1">Professores</span>
            <span v-else>Professor</span>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-badge v-for="(tt, index) in teachersSelected" class="mr-1 mb-1 cursor-pointer" :key="index"
              v-b-tooltip.hover.top="tt.email">
              {{ tt.label }}
            </b-badge>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group label="Duração *">
              <validation-provider #default="{ errors }" name="Duração" rules="required">
                <flat-pickr v-model="record.duration" placeholder="Duração" class="form-control" :config="{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true,
                }" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Número de Aulas *" class="fluid">
              <validation-provider #default="{ errors }" name="Número de Aulas" rules="required">
                <b-form-spinbutton id="sb-inline" min="1" v-model="record.lesson_number" inline />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Imagem">
          <div class="form-label-group">
            <img @click="$refs.fileInput.click()" :src="urlImage" id="thumbnail-youtube"
              class="img-fluid rounded-shadow cursor-pointer" />
            <input style="display: none" ref="fileInput" type="file" accept="image/*" @change="onFileChange" />
          </div>
        </b-form-group>
        <button--c :variant="'info'" :iconsize="'20'" :icon="null" :permission="record.id > 0
            ? 'permission.course.edit'
            : 'permission.course.create'
          " :title="'Salvar'" @clicked="validationForm()" class="mr-1" />
        <button--c v-if="record.id > 0" :variant="'gradient-danger'" :iconsize="'20'" :icon="null"
          :permission="'permission.course.delete'" :title="'Excluir'" @clicked="confirmDelete()" class="mr-1" />
      </b-form>
    </validation-observer>
  </b-overlay>
</template>
<script>
import _coursesService from "@/services/courses-service";
import _disciplineService from "@/services/discipline-service";
import _teachersService from "@/services/teachers-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { BModal, VBTooltip } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { _classificationService } from '@/services/classification-service';

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    dto: {
      type: Object,
      required: true,
    },
  },
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userData: {
        id_external: JSON.parse(localStorage.getItem("userData"))?.id_external ?? null
      },
      debounceMilliseconds: 1000,
      timeout: null,
      isloading: false,
      isloadingAutocomplete: false,
      isloadingModal: false,
      isLoadingTeacher: false,
      record: {},
      stateSelected: null,
      states: [],
      inputDisciplineProps: {
        id: "autodisciplines__input_ajax",
        placeholder: "Digite o nome da Disciplina",
        class: "form-control",
        name: "ajax",
      },
      inputTeacherProps: {
        id: "autodisciplines__input_ajax",
        placeholder: "Digite o nome do Professor",
        class: "form-control",
        name: "ajax",
      },
      inputClassificationProps: {
        id: "autoclassifications__input_ajax",
        placeholder: "Digite o nome da Classificação",
        class: "form-control",
        name: "ajax",
      },
      queryDiscipline: "",
      disciplines: [],
      disciplineSelected: null,
      discipline_name: null,
      disciplineConfigs: {
        disciplines: {
          //limit: 20,
          label: "Disciplina",
          onSelected: (selected) => {
            this.disciplineSelected = selected.item;
          },
        },
      },
      queryClassification: "",
      classifications: [],
      classificationSelected: {
        id: null,
        name: null
      },
      classification_name: null,
      classificationConfigs: {
        classifications: {
          //limit: 20,
          label: "Classificação",
          onSelected: (selected) => {
            this.classificationSelected = selected.item;
            this.queryClassification = this.classificationSelected.name
          },
        },
      },
      selectedFile: null,
      urlImage: `${require("@/assets/images/pages/sem-foto.png")}`,
      teachers: [],
      teachersSelected: [],
      disciplineNew: {
        id: 0,
        name: "",
      },
      newClassification: {
        name: "",
        available: false,
      }
    };
  },
  async created() {
    this.states = this.$utils.getStates();

    this.getInitials();
    await this.getRecord();
    localize("pt_BR", pt_br);
  },
  watch: {
    queryClassification(value, oldValue) {
      if (value == null || value == "" || value === this.classificationSelected.name) {
        this.newClassification.available = false
      } else {
        this.newClassification.available = true
      }

      if (value === null || value === "") {
        this.classificationSelected = {
          id: null,
          name: "",
        }
      }

      if (oldValue == this.classificationSelected.name) {
        if (value !== this.classificationSelected.name) {
          this.classificationSelected = {
            id: null,
            name: "",
          }
        }
      }
    }
  },
  methods: {
    validationForm() {
      this.$refs.courseRules.validate().then(async (success) => {
        if (!success) {
          return;
        }

        if (this.disciplineSelected && this.record.discipline_id != this.disciplineSelected.id) {
          const aliasAvailability = await _classificationService.checkClassificationAliasAvailability(this.dto.company_id, this.disciplineSelected.name);

          if (!aliasAvailability.content.available && this.classificationSelected.id && aliasAvailability.content.category_id != this.classificationSelected.id) {
            this.$swal({
              title: "Classificação Indisponível",
              text: `Não é possível utilizar a classificação selecionada, pois a disciplina \"${this.disciplineSelected.name}\" já esta associada a classificação \"${aliasAvailability.content.category_name}\". Selecione essa classificação ou deixe o campo em branco.`,
              icon: "warning",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-info",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            });
            return;
          }
        }
        
        this.save();
      });
    },
    validationFormModal() {
      this.$refs.disciplineNewRules.validate().then((success) => {
        if (success) {
          this.saveDiscipline();
        }
      });
    },
    getInitials() {
      this.record = this.dto;
      this.record.discipline_id = null;
      this.record.teacher_id = null;
      this.record.lesson_number = 1;
      this.record.image = null;
      this.record.name = null;
      this.record.canChange = true;
      this.record.duration = "";
      this.record.description = null;
    },
    async getRecord() {
      if (this.dto.id > 0) {
        this.isloading = true;
        _coursesService
          .find(this.dto.id)
          .then(async (res) => {
            this.record = res.content.course;
            this.record.canChange = res.content.canChange;

            if (this.record.image) {
              this.urlImage = `${res.pathimage}/${this.record.image}`;
            }

            if (this.record.discipline) {
              this.disciplineSelected = {
                id: this.record.discipline.id,
                name: this.record.discipline.name,
              };
              this.queryDiscipline = this.disciplineSelected.name;
              this.record.discipline = null;
            }

            if (this.record.classification_platform_id) {
              const found = await _classificationService.platformGetOne(this.dto.company_id, this.record.classification_platform_id)
              if (found?.content?.id) {
                this.classificationSelected = {
                  id: found.content.id,
                  name: found.content.name
                }
                this.queryClassification = found.content.name;
                this.record.classification = null;
              }
            }

            if (this.record.teachers) {
              this.teachersSelected = this.record.teachers.map((m) => {
                return {
                  label: m.teacher.name,
                  value: m.teacher.id,
                  email: m.teacher.email,
                };
              });
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      } else {
        this.isloading = true;
        _coursesService
          .duration(this.$route.params.id)
          .then((res) => {
            this.record.duration = res.content.lesson_duration + ":00";
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    getDisciplines() {
      if (this.queryDiscipline && this.queryDiscipline.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloadingAutocomplete) {
            this.isloadingAutocomplete = true;
            _disciplineService
              .autoComplete(this.queryDiscipline)
              .then((res) => {
                this.disciplines = [{ name: "disciplines", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingAutocomplete = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    getClassifications() {
      if (this.queryClassification && this.queryClassification.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloadingAutocomplete) {
            this.isloadingAutocomplete = true;
            _classificationService
              .platformAutoComplete(this.dto.company_id, this.queryClassification)
              .then((res) => {
                this.classifications = [{ name: "classifications", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingAutocomplete = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.isloading = true;
      _coursesService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$emit("result", "ok");
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onFileChange(e) {
      e.preventDefault();
      this.selectedFile = e.target.files[0];
      this.urlImage = URL.createObjectURL(e.target.files[0]);
    },
    save() {

      if (this.disciplineSelected)
        this.record.discipline_id = this.disciplineSelected.id;

      if (this.classificationSelected?.id) {
        this.record.classification_platform_id = this.classificationSelected.id
      } else {
        this.record.classification_platform_id = null
      }

      if (this.teachersSelected && this.teachersSelected.length > 0) {
        this.record.teachers = this.teachersSelected.map((m) => {
          return {
            teacher_id: m.value,
          };
        });
      }

      this.record.classification_id = null;

      let payload = new FormData();

      if (this.selectedFile) {
        payload.append("thumbnail", this.selectedFile);
      }
      payload.append("dados", JSON.stringify(this.record));

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _coursesService.create(payload)
          : _coursesService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Curso salvo com sucesso.");
          this.$emit("result", { status: "ok" });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    openModal() {
      this.disciplineNew = {
        id: 0,
        name: "",
      };
      this.$refs["modal-discipline"].show();
    },
    openTeacherModal() {
      this.teacherNew = {
        id: 0,
        name: "",
        email: "",
        uf: "",
      };
      this.$refs["modal-teacher"].show();
    },
    closeModal(ref) {
      this.$refs[ref].hide();
    },
    saveDiscipline() {
      let sError = "";
      if (!this.disciplineNew.name) {
        sError = "Necessário informar o nome!";
      }

      if (sError) {
        this.$utils.toastError("Notificação", sError);
      } else {
        this.isloadingModal = true;
        let discipline = {
          name: this.disciplineNew.name,
        };

        const payload = { data: { ...discipline } };

        const _createOrUpdate = _disciplineService.create(payload);

        _createOrUpdate
          .then((res) => {
            if (res.content && res.content.id && res.content.id > 0) {
              this.disciplines = [
                {
                  name: "disciplines",
                  data: [
                    {
                      id: res.content.id,
                      name: this.disciplineNew.name,
                    },
                  ],
                },
              ];

              this.disciplineSelected = {
                id: res.content.id,
                name: res.content.name,
              };
              this.queryDiscipline = this.disciplineSelected.name;

              this.$refs["modal-discipline"].hide();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloadingModal = false));
      }
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.isLoadingTeacher) {
          this.isLoadingTeacher = true;
          setTimeout(() => {
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  this.teachers = this.populardropdown(
                    res.content,
                    "name",
                    "id",
                    "email"
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isLoadingTeacher = false));
          }, 1500);
        }
      }
    },
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
  },
};
</script>
<style>
#thumbnail-youtube {
  border: 1px solid #999;
  width: 100px;
  height: 70px;
  border-radius: 10%;
}

#thumbnail-youtube:hover {
  opacity: 0.5;
}
</style>