<template>
  <div>
    <b-modal
      id="modal-cancelar"
      cancel-variant="outline-secondary"
      ok-title="Cancelar"
      cancel-title="Fechar"
      centered
      title="Cancelar Agendamento"
      @ok="onClickCancel(false)"
    >
      <b-form>
        <b-form-group label="Motivo *">
          <v-select
            v-model="reasonSelected"
            :options="reason"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Descrição (Opcional)">
          <b-form-textarea
            rows="7"
            v-model="reason_desc"
            placeholder="Informe uma breve descrição (opcional)"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      v-model="showCancelChilds"
      id="modal-cancelar-childs"
      cancel-variant="outline-secondary"
      ok-title="Quero cancelar!"
      cancel-title="Fechar"
      centered
      :title="`Cancelamento do Agendamento #${cancelItem.appointment_id}`"
      @ok="onClickCancel(true)"
      v-if="cancelItem"
    >
      <div class="row">
        <div class="col-12">
          <h3>(gravações off)</h3>
          <strong
            >Ao confirmar será cancelado também os agendamentos filhos
            abaixo.</strong
          >
          <ul>
            <li v-for="ch in appointment_childs" :key="ch">{{ ch }}</li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-card>
      <b-overlay :variant="skin" :show="!project" spinner-variant="primary" spinner-type="grow">
        <h4 class="mb-2 text-truncate">{{ project }}</h4>
      </b-overlay>
      <b-row class="mb-1 mb-sm-0">
        <b-col md="8">
          <button--c
            :variant="'gradient-info'"
            :iconsize="'20'"
            :icon="null"
            :permission="'permission.events.create'"
            :title="'Cadastrar Evento'"
            @clicked="onClickSave(null)"
            class="mb-2"
            v-if="!project_system_default"
          />
          <button--c
            :variant="history ? 'gradient-info' : 'outline-info'"
            :iconsize="'20'"
            :icon="null"
            :permission="'permission.events.create'"
            :title="'Historico'"
            @clicked="onClickhistorico"
            class="mx-2 mb-2"
            :disabled="isloading"
          />
        </b-col>
        <b-col md="4">
          <b-input-group>
            <b-form-input
              placeholder="Pesquise por Nome..."
              autocomplete="off"
              v-model="search"
              @keyup.enter="filter"
            />
            <b-input-group-append>
              <b-button variant="info" @click="filter">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span>Pesquisar</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-overlay
        :variant="skin"
        :show="isloading"
        spinner-variant="primary"
        spinner-type="grow"
      >
        <Table
          v-if="!recurence"
          :fields="!history ? fields : fieldsHis"
          :list="
            !history
              ? list[currentePage] && list[currentePage]
                ? list[currentePage].itens
                : []
              : list
              ? list
              : []
          "
          @orderBy="getRecordsOrderBy"
          border="full"
          responsive
        >
          <template #name="data">
            <div
              :data-text="data.item.name"
              class="text-truncate ellipsis"
              style="max-width: 600px"
            >
              {{ data.item.name }}
            </div>
          </template>
          <template #scheduled="data">
            <div
              class="text-nowrap d-flex justify-content-center"
              v-if="!data.item.parents_active"
            >
              <b-avatar
                :variant="data.item.scheduled ? 'success' : 'danger'"
                :id="`scheduled-description-${data.item.id}-${data.item.appointment_id}`"
                size="25"
                :to="data.item.appointment_id > 0 ? `/pedagogic/calendar?q=${data.item.appointment_id}` : ''"
              >
                <feather-icon
                  size="16"
                  :icon="data.item.scheduled ? 'CheckIcon' : 'XIcon'"
                />
              </b-avatar>
              <b-tooltip
                :title="data.item.scheduled_description"
                :target="`scheduled-description-${data.item.id}-${data.item.appointment_id}`"
              />
            </div>
            <div class="text-nowrap d-flex justify-content-center" v-else>
              <b-avatar variant="warning" size="25">
                <feather-icon
                  :id="`scheduled-description-${data.item.id}`"
                  icon="AlertCircleIcon"
                  @click="onClickCalendar(data.item)"
                  size="16"
                />
              </b-avatar>
              <b-tooltip
                title="Existem eventos recorrentes filhos agendados"
                :target="`scheduled-description-${data.item.id}`"
              />
            </div>
          </template>
          <template #sync_calendar="data">
            <div class="text-nowrap d-flex justify-content-center">
              <b-avatar
                :variant="data.item.sync_calendar ? 'success' : 'danger'"
                size="25"
              >
                <feather-icon
                  :icon="data.item.sync_calendar ? 'CheckIcon' : 'XIcon'"
                  @click="onClickCalendar(data.item)"
                  size="16"
                />
              </b-avatar>
            </div>
          </template>
          <template #sync_youtube="data">
            <div class="text-nowrap d-flex justify-content-center">
              <b-avatar
                :variant="data.item.sync_youtube ? 'success' : 'danger'"
                size="25"
              >
                <feather-icon
                  size="16"
                  :icon="data.item.sync_youtube ? 'CheckIcon' : 'XIcon'"
                  @click="onClickYoutube(data.item)"
                />
              </b-avatar>
            </div>
          </template>

          <template #appointment_id="data">
            <div class="text-nowrap d-flex justify-content-center">
              <strong
                v-if="data.item.appointment_id"
                @click="
                  $router.push({
                    name: 'pedagogic-calendar',
                    query: { q: data.item.appointment_id },
                  })
                "
                >#{{ data.item.appointment_id }}</strong
              >
              <span v-else>--</span>
            </div>
          </template>

          <template #start="data">
            <div
              v-if="data.item.start"
              :data-text="statusDateFormatted(data.item.start)"
              class="ellipsis"
            >
              {{ statusDateFormatted(data.item.start).split("-")[0] }}
            </div>
            <div v-else>--</div>
          </template>

          <template #actions="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="!data.item.subevents"
                :id="`warning-event-row-${data.item.id}`"
                icon="AlertTriangleIcon"
                size="16"
                class="mx-1"
                v-b-tooltip.hover.left="'Esse evento não possui sub eventos'"
                style="color: #ef9b00"
              />

              <feather-icon
                :id="`details-event-row-${data.item.id}`"
                icon="TrelloIcon"
                size="16"
                class="mx-1"
                v-b-tooltip.hover.top="'Sub Eventos'"
                @click="onClickDetails(data.item)"
              />

              <feather-icon
                :id="`edit-event-row-${data.item.id}`"
                icon="EditIcon"
                size="16"
                class="mx-1"
                v-b-tooltip.hover.top="'Editar Evento'"
                @click="onClickSave(data.item)"
                v-if="!project_system_default"
              />

              <feather-icon
                :id="`cancel-event-row-${data.item.id}`"
                v-if="data.item.canCancel"
                icon="XCircleIcon"
                size="16"
                class="mx-1"
                v-b-modal.modal-cancelar
                v-b-tooltip.hover.top="'Cancelar'"
                @click="onClickConfirmCancel(data.item)"
              />

              <feather-icon
                :id="`rescheduling-events-row-${data.item.id}`"
                v-if="data.item.can_rescheduling"
                icon="CalendarIcon"
                size="16"
                class="mx-1"
                v-b-tooltip.hover.top="'Reagendar'"
                @click="onClickRescheduling(data.item)"
              />

              <!-- onClickClone -->
              <feather-icon
                :id="`clone-events-row-${data.item.id}`"
                icon="CopyIcon"
                size="16"
                class="mx-1"
                v-if="btClone"
                v-b-tooltip.hover.top="'Clonar Evento'"
                @click="onClickClone(data.item)"
              />
            </div>
          </template>
        </Table>
        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1 && !history && !recurence"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-overlay>

      <b-sidebar
        sidebar-class="sidebar-lg"
        bg-variant="white"
        v-model="isSave"
        right
        backdrop
        shadow
        no-header
      >
        <template #default="{ hide }" v-if="isSave">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 v-if="dto.id" class="mb-0">Editar Evento</h5>
            <h5 v-else class="mb-0">Cadastrar Evento</h5>
            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>
          <project-event-form @result="onResult" :dto="dto" />
        </template>
      </b-sidebar>
    </b-card>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import Table from "@/components/Table.vue";
import { BModal, VBModal, VBTooltip } from "bootstrap-vue";
import { VBToggle } from "bootstrap-vue";
import _eventService from "@/services/event-service";
import _projectsService from "@/services/projects-service";
import _externalDataBaseService from "@/services/external-database-service";
import project_event from "./project-event";
import _reasonService from "@/services/reason-service";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
    "project-event-form": project_event,
    BModal,
    VBModal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      project: null,
      project_system_default: false,
      btClone: false,
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "appointment_id", label: "Agendamento" },
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        { key: "start", label: "Data" },
        { key: "scheduled", label: "Agendado" },
        { key: "sync_calendar", label: "Calendar" },
        { key: "sync_youtube", label: "youtube" },
        { key: "actions", label: "Ações" },
      ],
      fieldsHis: [
        { key: "appointment_id", label: "Agendamento" },
        { key: "name", label: "Nome", orderBy: { type: "front" } },
        { key: "start", label: "Data", orderBy: { type: "front" } },
        { key: "scheduled", label: "Agendado", orderBy: { type: "front" } },
        {
          key: "sync_calendar",
          label: "Sinc. Calendar",
          orderBy: { type: "front" },
        },
        {
          key: "sync_youtube",
          label: "Sinc. Youtube",
          orderBy: { type: "front" },
        },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: "",
      },
      isSave: false,
      dto: null,
      cancelItem: null,
      reason: [],
      reasonSelected: null,
      reason_desc: "",
      history: false,
      appointment_childs: [],
      showCancelChilds: false,
    };
  },
  created() {
    this.btClone = this.$utils.isPermited("permission.events.clone");
    this.getRecords(this.currentePage);
    this.getProject();
    this.getReason();
  },
  methods: {
    getProject() {
      if (this.$route.params.id > 0) {
        _projectsService
          .find(this.$route.params.id)
          .then((res) => {
            if (res.content) {
              this.project = res.content.name;
              this.project_system_default = res.content.system_default;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    statusDateFormatted(date) {
      if (date) {
        let dateSplited = date.split("T");
        let dateFormatted = dateSplited[0].split("-").reverse().join("/");
        return dateFormatted + " - " + dateSplited[1].substr(0, 8);
      } else {
        return "--";
      }
    },
    getReason() {
      _reasonService
        .show()
        .then((res) => {
          this.reason = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      this.history = false;
      this.recurence = false;
      _eventService
        .show(_page, this.$route.params.id, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickDetails(record, _) {
      this.$router.push({
        path: `/pedagogic/project-view/${this.$route.params.id}/event/${record.id}`,
      });
    },
    onClickSave(_record) {
      this.dto = {
        id: _record ? _record.id : 0,
        project_id: _record ? _record.project_id : this.$route.params.id,
      };
      this.isSave = true;
    },
    onClickRescheduling(_item) {
      this.$router.push({
        path: `/pedagogic/appointment/${_item.appointment_id}`,
      });
    },
    onClickYoutube(_record) {
      this.$utils.toastError("DESCULPE!!!", "função YOUTUBE não disponivel");
    },
    onClickhistorico() {
      this.recurence = false;
      if (!this.history) {
        this.isloading = true;
        _eventService
          .showFull(1, this.search, this.$route.params.id)
          .then((res) => {
            if (res.content) {
              this.list = res.content;
            }
            this.history = !this.history;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {
            this.isloading = false;
          });
      } else {
        this.list = [{ page: 0, itens: [] }];
        this.history = !this.history;
        this.currentePage = 1;
        this.getRecords(this.currentePage, true);
      }
    },
    onClickCalendar(_record) {
      if (_record.google_calendar_id) {
        var a = document.createElement("a");
        a.target = "_blank";
        a.href = `${_record.google_event_url}`;
        a.click();
      }
    },
    onClickConfirmCancel(_item) {
      this.cancelItem = _item;
    },
    onClickCancel(childs = false) {
      if (!this.reasonSelected) {
        this.$utils.toastError("OPS!!!", "Selecione o motivo.");
        return;
      }

      const payload = {
        reason_id: this.reasonSelected.value,
        reason_desc: this.reason_desc,
        id: this.cancelItem.id,

        appointment_id: this.cancelItem.appointment_id,
        cancel_childs_confirm: childs,
      };

      this.isloading = true;
      _eventService
        .cancelWithReason(payload)
        .then((res) => {
          if (res.content) {
            this.$utils.toast("Cancelamento", "Sucesso ao cancelar.");
            this.getRecords(1);
          }
        })
        .catch((error) => {
          if (error && error.split("-")[0] == "cancel_childs_confirm") {
            this.appointment_childs = error.split("-")[1].split(",");
            this.showCancelChilds = true;
          } else {
            this.$utils.toastError("Notificação", error);
          }
        })
        .finally(() => ((this.isloading = false), this.onResult()));
    },
    onClickClone(item) {
      this.$swal({
        title: `Clonar Evento ${item.name}?`,
        text: ``,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Sim, quero Clonar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.onClickCloneConfirm(item);
        }
      });
    },
    onClickCloneConfirm(item) {
      const payload = {
        data: {
          parent_clone_id: item.id,
          id: item.id,
        },
      };

      this.isloading = true;
      _eventService
        .clone(payload)
        .then((res) => {
          if (res.content) {
            this.$utils.toast("Clone", "Clonado com sucesso.");
            this.list.push(res.content);
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => ((this.isloading = false), this.onResult()));
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
      this.filter();
    },
  },
};
</script>
<style>
.collapse .card-body {
  padding: 0px !important;
}
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  padding: 0.5rem 0rem;
  font-size: 12px;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  font-size: 12px;
  z-index: 10;
}
</style>